<template>
    <div v-if="this.pageData">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="news & stories" />
        <main>
            <section class="green-banner" v-if="this.pageData.attributes.field_description_w_summary.summary">
                <div class="container"> <p> {{ this.pageData.attributes.field_description_w_summary.summary }} </p> </div>
            </section>
            <section class="news main-content">
                <div class="container container-items" v-if="this.newsData">
                    <NewsTile v-for="(n, index) in this.newsData" :key="'news'+index"
                        :routesData="routesData" :newsData="n" />
                </div>
            </section>
        </main>
    </div>
</template>
<script>
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import NewsTile from '../components/NewsTile.vue'
import { fetchNodes, fetchSinglePage, fetchRoutes } from '../libs/drupalClient'
    export default {
        name: 'news',
        components: {
            HeaderIntroImage,
            NewsTile
        },
        data: () => {
            return {
                pageData: null,
                currentPageID: "00125d9c-4e8b-4aee-ba52-b232e4dceeba",
                newsData: null,
                routesData: null
            }
        },
        computed: {},
        methods: {},
        async mounted() {
            await fetchSinglePage(this.currentPageID, {
                include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text']
            })
            .then(res => {
                this.pageData = res
            })

            await fetchNodes('news', {
                include: ['field_image', 'field_attachment']
            }).then(res => {
                this.newsData = res

                this.newsData = res.sort((a, b) => {
                    return a.attributes.field_date > b.attributes.field_date ? -1 : 1
                })

            })

            await fetchRoutes().then(res => {
                 this.routesData = res.routes
            })
        }
    }
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.news { background-color: #EBF0F5; }

.container-items {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
}

@media (min-width: 1024px) {
    .container-items {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 4rem;
    }
    .item {
        grid-column: span 1;
        .content { padding: 2rem 2rem; }
        h2 {
            font-size: 1.75rem;
            line-height: 2.1875rem;
        }
    }
}

@media (min-width: 1300px) {
    .container-items {
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>